import React, { useEffect, useState } from 'react';
import { Link } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';
import { trackGAEvent } from '../../utils/gaTracking';

const GA = {
  ACTION: 'Clicked',
  LABEL: 'SustainabilityKnowMore',
};

let KnowMoreModal;

const SustainabilityKnowMoreApp = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isModalLoaded, setModalLoaded] = useState(false);
  const { t } = useI18nContext();

  const onKnowMoreClick = () => {
    trackGAEvent(GA.ACTION, GA.LABEL);
    setModalVisible(true);
  };

  useEffect(() => {
    (async () => {
      if (!isModalLoaded) {
        try {
          KnowMoreModal = (
            await import(/* webpackChunkName: "KnowMoreModal" */ './components/KnowMoreModal/KnowMoreModal')
          ).default;
          setModalLoaded(true);
        } catch (err) {}
      }
    })();
  });

  return (
    <>
      <Link onClick={onKnowMoreClick} kind="paragraph">
        {t('sustainability.knowMore')}
      </Link>
      {isModalLoaded && isModalVisible && <KnowMoreModal setModalVisible={setModalVisible} />}
    </>
  );
};

export default SustainabilityKnowMoreApp;

import { TAsqPayload } from 'types/TAsq';
import { TAsqMessageType } from './types';

import { CUSTOM_DIMENSIONS, GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import { trackGAEvent } from '../../../../utils/gaTracking';
import { GaFields, AsqHashParams } from './consts';
import { TListingClient } from 'types/TListing';
import createCustomDimension from '../../../../utils/createCustomDimension';

// This is the same event tracking as was in old asq-frontend, added here for consistency
// eslint-disable-next-line import/prefer-default-export
export const sendSubmitAsqGAEvents = (
  asqPayload: TAsqPayload,
  sellerType?: TListingClient['seller']['sellerType'],
  hasLicensePlate?: string,
): void => {
  trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SEmailSuccess, '', {
    ...createCustomDimension({
      dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
      payload: sellerType,
    }),
    ...createCustomDimension({
      dimension: CUSTOM_DIMENSIONS.AD_HAS_LICENSE_PLATE,
      payload: hasLicensePlate,
    }),
  });

  Object.keys(GaFields).forEach((name) => {
    if (Boolean(asqPayload[name]?.length)) {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.AsqFields, GaFields[name]);
    }
  });

  // We handle it separately because it's an object
  if (asqPayload['vehicleTradeInRequest']) {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.AsqFields, GaFields['vehicleTradeInRequest']);
  }
};

export const createShowAsqHash = (messageType?: TAsqMessageType, previousAction?: string): string => {
  const { SHOW_ASQ, MESSAGE_TYPE, PREVIOUS_ACTION } = AsqHashParams;
  const hashParams = {
    [SHOW_ASQ.KEY]: SHOW_ASQ.VALUE,
    ...(messageType && { [MESSAGE_TYPE.KEY]: messageType }),
    ...(previousAction && { [PREVIOUS_ACTION.KEY]: previousAction }),
  };

  return `#${new URLSearchParams(hashParams).toString()}`;
};

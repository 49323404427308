import React, { useEffect, useState } from 'react';

let LoginDialog;

type TLoginDialogAppProps = {
  onClose: Function;
  onSuccess: Function;
};

const LoginDialogContainer = ({ onClose, onSuccess }: TLoginDialogAppProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadComponent = async () => {
      try {
        LoginDialog = (await import(/* webpackChunkName: "loginDialog" */ './LoginDialog')).default;
        setIsLoaded(true);
      } catch (err) {}
    };

    loadComponent();
  }, []);

  if (!isLoaded) {
    return null;
  }

  return <LoginDialog onClose={onClose} onSuccess={onSuccess} />;
};

export default LoginDialogContainer;

import React, { useContext, useEffect, useState } from 'react';
import { ButtonIcon, MaskedLink } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../../../utils/gaTracking';
import { appendHashParam, deleteHashParam, getHashParamValue } from '../../../../utils/hashParams';
import registerPhoneClick from '../../repositories/registerPhoneClick';

import LoginDialogContainer from '../../../../components/LoginDialog/LoginDialogContainer';
import Asq from './Asq';
import SellerContactOptionsButton from './SellerContactOptionsButton';

import { TListing, TListingClient, TListingSellerInfo } from 'types/TListing';

import Classes from './SellerContactOptions.scss';
import { CUSTOM_DIMENSIONS, GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import createCustomDimension from '../../../../utils/createCustomDimension';
import PaymentProposalButton from './PaymentProposal/PaymentProposalButton';
import BuyItNowButton from './BuyItNow/BuyItNowButton';
import BuyItRefurbished from './BuyItRefurbished/BuyItRefurbished';

let PaymentProposalDialog;
let PhoneDialog;

type TSellerContactOptionsProps = {
  itemId: TListingClient['itemId'];
  title: TListingClient['title'];
  adImageThumbnail: string;
  priceInfo: TListingClient['priceInfo'];
  seller: TListingSellerInfo;
  isCarAd: TListingClient['isCarAd'];
  isCarDealer: TListingClient['flags']['isCarDealer'];
  isAdmarkt: TListingClient['flags']['isAdmarkt'];
  hasCallTracking: TListing['flags']['hasCallTracking'];
  isLeaseCar: TListing['flags']['isLeaseCar'];
  isInBinExcludedCategories?: TListing['flags']['isInBinExcludedCategories'];
};

const HASH_PARAMS = {
  SHOW_PHONE: {
    KEY: 'action',
    VALUE: 'showPhone',
  },
};

const SellerContactOptions = ({
  itemId,
  title,
  adImageThumbnail,
  priceInfo,
  seller,
  isCarAd,
  isAdmarkt,
  hasCallTracking,
  isCarDealer,
  isInBinExcludedCategories,
  isLeaseCar,
}: TSellerContactOptionsProps) => {
  const {
    name: sellerName,
    phoneNumber,
    isAsqEnabled,
    sellerWebsiteUrl,
    phoneNumberHidden,
    showProfilePicture,
    showSalesRepresentatives,
    financeAvailable,
    id: sellerId,
    sellerType,
    refurbishedAdDeepLink,
  } = seller;

  const {
    xsrfToken,
    userLoggedIn,
    userDetails: { isAdOwner = false } = {},
    hasLicensePlate,
    isBuyItNowEnabled,
    isPaymentProposalEnabled,
    links: { buyItNowPathPrefix },
    isVerifiedRefurbishedAd,
    isStickyCTaExperimentEnabled,
  } = useContext(EnvironmentContext);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [isPhoneDialogLoaded, setPhoneDialogLoaded] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [loginSuccessAction, setLoginSuccessAction] = useState('');
  const [showPaymentProposalDialog, setShowPaymentProposalDialog] = useState(false);

  const reloadAndShowPhoneDialog = () => {
    appendHashParam(HASH_PARAMS.SHOW_PHONE.KEY, HASH_PARAMS.SHOW_PHONE.VALUE);
    document.location.reload();
  };

  const loadPhoneDialog = async () => {
    if (!isPhoneDialogLoaded) {
      try {
        PhoneDialog = (await import(/* webpackChunkName: "PhoneDialog" */ './PhoneDialog')).default;
        setPhoneDialogLoaded(true);
        trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SPhoneBegin, 'click', {
          ...createCustomDimension({
            dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
            payload: sellerType,
          }),
          ...createCustomDimension({
            dimension: CUSTOM_DIMENSIONS.AD_HAS_LICENSE_PLATE,
            payload: hasLicensePlate,
          }),
        });
        registerPhoneClick({ itemId, xsrfToken, isCarAd, isLeaseCar });
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (getHashParamValue(HASH_PARAMS.SHOW_PHONE.KEY) === HASH_PARAMS.SHOW_PHONE.VALUE) {
      deleteHashParam(HASH_PARAMS.SHOW_PHONE.KEY);
      if (!phoneNumberHidden) {
        loadPhoneDialog();
        setShowPhoneNumber(true);
      }
    }
    // keep deps empty to run useEffect hook only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleWebsiteClick = () => {
    trackGAEvent(
      GA_TRACK_EVENTS_ACTIONS.R2SExternalBegin,
      '',
      createCustomDimension({
        dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
        payload: sellerType,
      }),
    );
  };

  const buyItNowHandler = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SBINBegin);
    location.assign(`${buyItNowPathPrefix}${itemId}`);
  };

  const loadAndShowPaymentDialog = async () => {
    PaymentProposalDialog = (
      await import(/* webpackChunkName: "PaymentProposalDialog" */ './PaymentProposal/PaymentProposalDialog')
    ).default;
    setShowPaymentProposalDialog(true);
  };

  const handleBuyItNowClick = () => {
    if (userLoggedIn) {
      buyItNowHandler();
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('buyItNow');
    }
  };

  const handlePaymentProposalClick = () => {
    if (userLoggedIn) {
      loadAndShowPaymentDialog();
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('paymentProposal');
    }
  };

  const handlePhoneNumberClick = () => {
    if (!phoneNumberHidden) {
      loadPhoneDialog();
      setShowPhoneNumber(true);
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('phone');
    }
  };

  const handleLoginSuccess = () => {
    setShowLoginDialog(false);
    switch (loginSuccessAction) {
      case 'phone':
        reloadAndShowPhoneDialog();
        break;

      case 'buyItNow':
        buyItNowHandler();
        break;

      case 'paymentProposal':
        loadAndShowPaymentDialog();
        break;
    }
  };

  const handlePhoneDialogClose = () => {
    setShowPhoneNumber(false);
  };

  // do not render an empty block if nothing to show
  if ((!isAsqEnabled || (isAsqEnabled && isAdOwner)) && !sellerWebsiteUrl && !phoneNumber && !showLoginDialog) {
    return null;
  }

  const getAdType = () => {
    if (isVerifiedRefurbishedAd) return 'VERIFIED_REFURBISHED';
    if (isAdmarkt) return 'ADMARKT';
    if (isCarAd && !isAdmarkt) return 'CAR_AD';
    return 'HORIZONTAL_AD';
  };

  const AdType = getAdType();

  const isSellerWebsiteUrl = Boolean(sellerWebsiteUrl);
  const isPhoneNumber = Boolean(phoneNumber);
  const numberOfButtons =
    Number(isAsqEnabled) + Number(isSellerWebsiteUrl) + Number(isPhoneNumber) + Number(isBuyItNowEnabled);

  switch (AdType) {
    case 'VERIFIED_REFURBISHED':
      return (
        <div
          className={`block-wrapper-s block-wrapper-s--hide-top ${!isStickyCTaExperimentEnabled ? Classes.root : `${Classes.root}-${numberOfButtons}`}`}
        >
          <BuyItRefurbished
            sellerName={sellerName}
            refurbishedAdDeepLink={refurbishedAdDeepLink as string}
            sellerId={sellerId}
            priceCents={priceInfo?.priceCents}
          />
        </div>
      );

    case 'ADMARKT':
      return (
        <div
          className={`block-wrapper-s block-wrapper-s--hide-top ${!isStickyCTaExperimentEnabled ? Classes.root : `${Classes.root}-${numberOfButtons}`}`}
        >
          {sellerWebsiteUrl && (
            <MaskedLink
              target="_blank"
              rel="nofollow"
              href={sellerWebsiteUrl}
              className={`hz-Button hz-Button--primary ${Classes.button} ${Classes.link}`}
              onClick={handleWebsiteClick}
            >
              <ButtonIcon name="website" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.website" />
            </MaskedLink>
          )}
          {(phoneNumberHidden || phoneNumber) && (
            <SellerContactOptionsButton
              isPrimary={!sellerWebsiteUrl}
              className={Classes.button}
              onClick={handlePhoneNumberClick}
            >
              <ButtonIcon name="call" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.showPhoneNumber" />
            </SellerContactOptionsButton>
          )}
          {isAsqEnabled && !isAdOwner && (
            <>
              <Asq
                itemId={itemId}
                isCarDealer={isCarDealer}
                isCarAd={isCarAd}
                title={title}
                sellerName={sellerName}
                sellerLocation={seller.location}
                isPrimary={!(phoneNumberHidden || phoneNumber) && !sellerWebsiteUrl}
                sellerId={sellerId}
                adImageThumbnail={adImageThumbnail}
                showProfilePicture={showProfilePicture}
                showSalesRepresentatives={showSalesRepresentatives}
                financeAvailable={financeAvailable}
                sellerType={sellerType}
                priceInfo={priceInfo}
              />
              {isPaymentProposalEnabled && !isInBinExcludedCategories && (
                <PaymentProposalButton
                  isPrimary={!(phoneNumberHidden || phoneNumber) && !sellerWebsiteUrl}
                  onClick={handlePaymentProposalClick}
                />
              )}
            </>
          )}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}
        </div>
      );

    case 'CAR_AD':
      return (
        <div className={`block-wrapper-s block-wrapper-s--hide-top ${Classes.root}`}>
          {(phoneNumberHidden || phoneNumber) && (
            <SellerContactOptionsButton isPrimary className={Classes.button} onClick={handlePhoneNumberClick}>
              <ButtonIcon name="call" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.showPhoneNumber" />
            </SellerContactOptionsButton>
          )}
          {isAsqEnabled && !isAdOwner && (
            <Asq
              itemId={itemId}
              isCarDealer={isCarDealer}
              isCarAd={isCarAd}
              title={title}
              sellerName={sellerName}
              sellerLocation={seller.location}
              isPrimary={!phoneNumberHidden && !phoneNumber}
              sellerId={sellerId}
              adImageThumbnail={adImageThumbnail}
              showProfilePicture={showProfilePicture}
              showSalesRepresentatives={showSalesRepresentatives}
              financeAvailable={financeAvailable}
              sellerType={sellerType}
              priceInfo={priceInfo}
              isLeaseCar={isLeaseCar}
            />
          )}
          {sellerWebsiteUrl && (
            <MaskedLink
              target="_blank"
              rel="nofollow"
              href={sellerWebsiteUrl}
              className={`hz-Button hz-Button--secondary ${Classes.button} ${Classes.link}`}
              onClick={handleWebsiteClick}
            >
              <ButtonIcon name="website" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.website" />
            </MaskedLink>
          )}
          {isPaymentProposalEnabled && isAsqEnabled && !isAdOwner && (
            <PaymentProposalButton
              isPrimary={!phoneNumberHidden && !phoneNumber && !sellerWebsiteUrl}
              onClick={handlePaymentProposalClick}
            />
          )}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}
        </div>
      );

    case 'HORIZONTAL_AD':
    default:
      return (
        <div
          className={`block-wrapper-s block-wrapper-s--hide-top ${!isStickyCTaExperimentEnabled ? Classes.root : `${Classes.root}-${numberOfButtons}`}`}
        >
          {isBuyItNowEnabled && isAsqEnabled && !isAdOwner && <BuyItNowButton onClick={handleBuyItNowClick} />}
          {isAsqEnabled && !isAdOwner && (
            <Asq
              itemId={itemId}
              isCarDealer={isCarDealer}
              isCarAd={isCarAd}
              title={title}
              sellerName={sellerName}
              sellerLocation={seller.location}
              isPrimary={!isBuyItNowEnabled}
              sellerId={sellerId}
              adImageThumbnail={adImageThumbnail}
              showProfilePicture={showProfilePicture}
              showSalesRepresentatives={showSalesRepresentatives}
              financeAvailable={financeAvailable}
              sellerType={sellerType}
              priceInfo={priceInfo}
            />
          )}
          {sellerWebsiteUrl && (
            <MaskedLink
              target="_blank"
              rel="nofollow"
              href={sellerWebsiteUrl}
              className={`hz-Button hz-Button--secondary ${Classes.button} ${Classes.link}`}
              onClick={handleWebsiteClick}
            >
              <ButtonIcon name="website" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.website" />
            </MaskedLink>
          )}
          {(phoneNumberHidden || phoneNumber) && (
            <SellerContactOptionsButton
              isPrimary={!isBuyItNowEnabled && !sellerWebsiteUrl && !isAsqEnabled}
              className={Classes.button}
              onClick={handlePhoneNumberClick}
            >
              <ButtonIcon name="call" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.showPhoneNumber" />
            </SellerContactOptionsButton>
          )}
          {isPaymentProposalEnabled && isAsqEnabled && !isAdOwner && (
            <PaymentProposalButton
              isPrimary={!isBuyItNowEnabled && !phoneNumberHidden && !phoneNumber && !sellerWebsiteUrl}
              onClick={handlePaymentProposalClick}
            />
          )}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}
        </div>
      );
  }
};

export default SellerContactOptions;

import React, { MouseEvent, useContext, useState, useEffect } from 'react';
import { Asset, Icon, Link } from '@hz-design-system/web-ui';
import { Trans, useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import { trackGAEvent } from '../../../../utils/gaTracking';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import getAdUrl from '../../utils/getAdUrl';
import getTrackingParams from '../../utils/getTrackingParams';

import { TListingClient } from 'types/TListing';

import Classes from './SocialShare.scss';

type TSocialShareProps = {
  listing: TListingClient;
};

type TLinksProps = {
  adTitle: string;
  facebookAppId: string;
  facebookReturnUrl: string;
  twitterHandle: string;
  emailSubject: string;
  navigatorShareIsAvailable: boolean;
};

const windowFeatures =
  'width=800,height=640,top=250,left=300,menubar=no,location=yes,resizable=yes,scrollbars=yes,status=no';

const LINKS = {
  Whatsapp: ({ navigatorShareIsAvailable }): string =>
    `${
      navigatorShareIsAvailable ? 'whatsapp://' : 'https://web.whatsapp.com/'
    }send?text=${getAdUrl()}${getTrackingParams('whatsapp-desktop')}`,
  Facebook: ({ facebookAppId, facebookReturnUrl }: TLinksProps): string =>
    `https://www.facebook.com/dialog/send?app_id=${facebookAppId}&link=${getAdUrl()}${getTrackingParams(
      'facebook-messenger',
    )}&redirect_uri=${facebookReturnUrl}&display=popup`,
  'Facebook-Messanger': ({ adTitle }: TLinksProps): string =>
    `https://www.facebook.com/share.php?u=${getAdUrl()}${getTrackingParams('facebook')}&t=${adTitle}`,
  Twitter: ({ adTitle, twitterHandle }: TLinksProps): string =>
    `https://twitter.com/intent/tweet?text=${adTitle}&url=${getAdUrl()}${getTrackingParams(
      'twitter_large',
    )}&via=${twitterHandle}`,
  Email: ({ adTitle, emailSubject }: TLinksProps): string =>
    `mailto:?subject=${emailSubject}:${adTitle}&body=%0D%0D%0D%0D${getAdUrl()}`,
};

let toastNotification;

const SocialShare = ({ listing }: TSocialShareProps) => {
  const { t } = useI18nContext();
  const {
    social: { facebookAppId, twitterHandle },
  } = useContext(EnvironmentContext);
  const [navigatorShareIsAvailable, setNavigatorShareIsAvailable] = useState(false);

  const showToastNotification = async () => {
    if (!toastNotification) {
      try {
        toastNotification = (
          await import(/* webpackChunkName: "toastNotification" */ '../../../../components/Toast/ToastNotification')
        ).default;
      } catch (err) {}
    }

    if (toastNotification) {
      toastNotification({
        type: 'success',
        description: t('share.linkCopied'),
      });
    }
  };

  const { title } = listing;

  const emailSubject = t('share.emailSubject');
  const adTitle = encodeURIComponent(title);

  const openSharePopup = (e: MouseEvent, network: string) => {
    e.preventDefault();
    trackGAEvent('ShareAdBegin', network);
    const facebookReturnUrl = `${window.location.origin}/plaats/thanks-for-sharing`;

    return window.open(
      LINKS[network]({
        adTitle,
        facebookReturnUrl,
        facebookAppId,
        twitterHandle,
        emailSubject,
        navigatorShareIsAvailable,
      }),
      '',
      windowFeatures,
    );
  };

  const copyLink = (e: MouseEvent) => {
    e.preventDefault();

    if (navigator.clipboard) {
      navigator.clipboard.writeText(getAdUrl()).then(() => {
        trackGAEvent('ShareAdBegin', 'Copy-Link');
        showToastNotification();
      });
    }
  };

  const shareNative = (e: MouseEvent) => {
    e.preventDefault();

    if (navigatorShareIsAvailable) {
      navigator.share({ title: adTitle, url: getAdUrl() });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (navigator?.share) {
      setNavigatorShareIsAvailable(true);
    }
  }, [setNavigatorShareIsAvailable]);

  return (
    <div className={`block-wrapper-s ${Classes.root}`}>
      <Trans i18nKey="share.with" className={Classes.with} />
      <Link href="#" onClick={(e: MouseEvent) => openSharePopup(e, 'Whatsapp')}>
        <Asset.Square name="SocialShareWhatsapp" size="l" />
      </Link>
      <Link className={Classes.hideOnMobile} href="#" onClick={(e) => openSharePopup(e, 'Facebook')}>
        <Asset.Square name="SocialShareMessenger" size="l" />
      </Link>
      <Link href="#" onClick={(e: MouseEvent) => openSharePopup(e, 'Facebook-Messanger')}>
        <Asset.Square name="SocialShareFacebook" size="l" />
      </Link>
      <Link href="#" onClick={(e: MouseEvent) => openSharePopup(e, 'Twitter')}>
        <Asset.Square name="SocialShareTwitter" size="l" />
      </Link>
      <Link href="#" onClick={(e: MouseEvent) => openSharePopup(e, 'Email')}>
        <Asset.Square name="SocialShareEmail" size="l" />
      </Link>
      {navigatorShareIsAvailable && (
        <Link className={Classes.other} href="#" onClick={shareNative}>
          <Asset.Square name="SocialShareOther" size="l" />
        </Link>
      )}
      <Trans i18nKey="share.withOr" className={Classes.hideOnMobile} />
      <div className={`${Classes.hideOnMobile} ${Classes.copyLink}`}>
        <Icon name="copy_link" color="signal-link-default" size="m" />
        <Link href="#" onClick={copyLink}>
          {t('share.copyLink')}
        </Link>
      </div>
    </div>
  );
};

export default SocialShare;

import React from 'react';
import { MaskedLink, Link, NoOpLink, Icon, VerticalListing } from '@hz-design-system/web-ui';
import { useI18nContext } from '@ecg-marktplaats/js-react-i18n';

import formatPrice from '../../../../utils/formatPrice';
import getFormattedLocation from '../../../../utils/getFormattedLocation';
import { getLargeImageUrl } from '../../../../utils/getImageUrls';

import { TAdmarktItem } from 'types/TAdmarkt';
import Classes from './AdmarktSimilarItems.scss';

type TAdmarktSimilarItemProps = {
  item: TAdmarktItem;
  isHorizontal: boolean;
};

const AdmarktSimilarItem = ({ item, isHorizontal }: TAdmarktSimilarItemProps) => {
  const { t } = useI18nContext();

  const formattedLocation = getFormattedLocation(item.location, t);
  const formattedPrice = formatPrice(item.price, t);
  const imageUrl = getLargeImageUrl(item.picture);
  const LinkComponent = item.thinContent ? MaskedLink : Link;

  if (isHorizontal) {
    return (
      <LinkComponent kind="block" className={Classes.item} href={item.url} data-ad-url={item.url}>
        <VerticalListing title={item.title} price={formattedPrice} imageSrc={imageUrl} imageLoading="lazy" />
      </LinkComponent>
    );
  }

  return (
    <LinkComponent kind="block" className={Classes.item} href={item.url} data-ad-url={item.url}>
      {imageUrl ? (
        <div className={Classes.imageContainer}>
          <img className={Classes.image} src={imageUrl} alt={item.title} loading="lazy" decoding="async" />
        </div>
      ) : (
        <div className={Classes.noImage}>
          <Icon size="xxl" name="image" />
        </div>
      )}
      <div className={Classes.data}>
        <NoOpLink as="span" className={Classes.title}>
          {item.title}
        </NoOpLink>
        <div className={Classes.price}>{formattedPrice}</div>
        <div className={Classes.location}>{formattedLocation}</div>
      </div>
    </LinkComponent>
  );
};

export default AdmarktSimilarItem;
